import { Controller } from 'stimulus'
import { airbrake } from '../entrypoints/airbrake'

export default class extends Controller {
  static targets = ['form', 'cardAuthorized', 'submitButton', 'hpfToken', 'encKey','genericError', 'loadingSpinner', 'cardCountry', 'cardState']

  connect() {
    PTPayment.setup({
      authorization: { clientKey: this.element.dataset.clientKey },
      styles: {
        'code': this.customStyle(),
        'cc': this.customStyle(),
        'exp': this.customStyle()
      }
    }).then(function(instance){
      this.paytraceForm = instance
    }.bind(this))
  }

  submit(event) {
    event.preventDefault()
    this.clearInputErrors()

    if (!this.formTarget.checkValidity()) {
      let elements = this.formTarget.elements
      for (let index = 0; index < elements.length; index++) {
        var element = elements[index]
        if (element.willValidate === true) {
          element.classList.toggle('is-invalid', !element.checkValidity())
        }
      }
      this.formTarget.querySelector('.is-invalid').focus()
      return false
    }

    if(this.hasCardAuthorizedTarget && !this.cardAuthorizedTarget.checked){
      this.cardAuthorizedTarget.classList.add('is-invalid')
      return
    }

    PTPayment.validate(function(validationErrors) {
      if (validationErrors.length > 0) {
        this.handleValidationErrors(validationErrors)
      } else {
        this.paytraceForm.process()
        .then( r => this.submitCardToken(r))
        .catch( err => this.showGenericErrorAndLogAirbreak(err))
      }
    }.bind(this))
  }

  submitCardToken(response) {
    this.showSpinner()
    this.hpfTokenTarget.value = response.message.hpf_token
    this.encKeyTarget.value = response.message.enc_key
    this.formTarget.submit()
  }

  handleValidationErrors(errors) {
    let expected_error = false
    errors.forEach(error => {
      if(error.responseCode == '35') { this.setInputInError('cc'); expected_error = true }
      if(['43', '44'].includes(error.responseCode)) { this.setInputInError('exp'); expected_error = true }
      if(error.responseCode == '148') { this.setInputInError('code'); expected_error = true }
    })
    if(!expected_error) {
      this.showGenericErrorAndLogAirbreak(errors)
    }
  }

  showGenericErrorAndLogAirbreak(error_data) {
    this.genericErrorTarget.classList.remove('d-none')
    if(airbrake) {
      airbrake.notify({
        error: 'An unexpected error occured when registering a card with Paytrace',
        params: error_data
      })
    }else{
      console.log(error_data)
    }
  }

  hideGenericError() {
    this.genericErrorTarget.classList.add('d-none')
  }

  setInputInError(input_short_name) {
    let params = {}
    params[input_short_name] = { 'border_color': '#dc3545' }
    PTPayment.style(params)
  }

  clearInputErrors() {
    ['cc', 'exp', 'csc'].forEach(input_short_name => {
      let params = {}
      params[input_short_name] = { 'border_color': '#ced4da' }
      PTPayment.style(params)
    })
    if(this.hasCardAuthorizedTarget) {
      this.cardAuthorizedTarget.classList.remove('is-invalid')
    }
    this.hideGenericError()
  }

  customStyle() {
    return {
      'label_margin': '10px 0 5px 0',
      'label_color':'#212529',
      'label_size':'0.813rem',
      'label_font':'"Roboto", sans-serif',
      'label_font_weight':'normal',
      'font_color':'#212529',
      'border_color':'#ced4da',
      'input_border_width:': '1px',
      'border_style':'solid',
      'font_size':'0.813rem',
      'input_font':'"Roboto", sans-serif',
      'background_color':'#dfe5e6',
      'input_margin':'3px 0px 3px 0px',
      'input_padding': '0.375rem 0.75rem'
    }
  }

  showSpinner() {
    this.formTarget.classList.add('d-none')
    this.loadingSpinnerTarget.classList.remove('d-none')
  }
}
