import "@rails/ujs"
import * as Turbolinks from "turbolinks"
import BSN from "bootstrap.native"
import "../controllers"

Turbolinks.start()

document.addEventListener("turbolinks:load", () => {
  BSN.initCallback(document.body)
})
