import { airbrake } from '../entrypoints/airbrake'
import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit', 'loading', 'fields', 'field', 'validationError', 'hiddenForm', 'hiddenPaymentToken', 'authorizationAccuracy',
    'bankName', 'hiddenBankName', 'accountNumber', 'routingNumber', 'nameOnAccount', 'signature', 'hiddenSignature', 'authorization']

  connect() {
    this.submitting = false
    this.configureConnectJS()
  }

  configureConnectJS() {
    CollectJS.configure({
        'customCss': {
          'background-color': '#dfe5e6',
          'padding': '0.375rem 0.75rem',
          'border-style': 'solid',
          'border-width': '1',
          'border-color': '#ced4da',
          'border-radius': '0',
          'height': 'calc(1.624em + 0.75rem + 2px)',
          'font-size': '0.813rem',
          'color': '#435363'
        },
        'invalidCss': {
          'border-color': '#dc3545'
        },
        'variant': 'inline',
        'timeoutDuration': 5000, // Time CollectJS will wait for confirmation that account info has been validated
        'timeoutCallback' : function() { // Reenable submission in case of a timeout
          this.submitting = false
          this.showValidationErrors(true)
          this.enableSubmit(true)
        }.bind(this),
        'callback' : function(response) {
            this.showLoading(true)
            this.fillHiddenForm(response)
            this.submitHiddenForm()
        }.bind(this),
        'fieldsAvailableCallback' : function() {
          this.displayAllFields()
        }.bind(this),
        'validationCallback' : function(_field, valid, _message) {
          if (!valid && this.submitting) {
            this.submitting = false
            this.showValidationErrors(true)
            this.enableSubmit(true)
          }
        }.bind(this)
    })
  }

  displayAllFields() {
    this.loadingTarget.classList.add('d-none')
    this.fieldTargets.forEach(function(field) {
      field.classList.remove('d-none')
    })
  }

  submit(event) {
    event.preventDefault()
    this.storeAccount()
  }

  storeAccount() {
    this.submitting = true
    this.hideErrors()
    this.enableSubmit(false)
    if (!this.valid()) {
      this.enableSubmit(true)
      return
    }
    CollectJS.startPaymentRequest()
  }

  submitHiddenForm() {
    let url = this.hiddenFormTarget.action
    let data = Rails.serializeElement(this.hiddenFormTarget)
    Rails.ajax({
      type: this.hiddenFormTarget.method,
      url: url,
      data: data,
      dataType: 'json',
      success: function(response) {
        if(response.status == 'success'){
          window.location.href = response.url
        }
        else {
          this.enableSubmit(true)
          this.showLoading(false)
          this.showValidationErrors(true)
        }
      }.bind(this),
      error: function(error) {
        this.enableSubmit(true)
        this.showLoading(false)
        this.showValidationErrors(true)
      }.bind(this)
    })
  }

  // Place the token into the hidden form
  fillHiddenForm(response_data) {
    this.hiddenPaymentTokenTarget.value = response_data.token
    this.hiddenBankNameTarget.value = this.bankNameTarget.value
    if (this.hasSignatureTarget) { this.hiddenSignatureTarget.value = this.signatureTarget.value }
    this.accountNumberTarget.value = response_data.check.account
    this.routingNumberTarget.value = response_data.check.aba
    this.nameOnAccountTarget.value = response_data.check.name
  }

  showLoading(show) {
    if (show) {
      this.loadingTarget.classList.remove('d-none')
      this.fieldsTarget.classList.add('d-none')
    } else {
      this.loadingTarget.classList.add('d-none')
      this.fieldsTarget.classList.remove('d-none')
    }
  }

  showValidationErrors(show) {
    if (show) {
      this.validationErrorTarget.classList.remove('d-none')
      this.showLoading(false)
    } else {
      this.validationErrorTarget.classList.add('d-none')
    }
  }

  hideErrors() {
    this.showValidationErrors(false)
  }

  enableSubmit(enable) {
    this.submitTarget.disabled = !enable
  }

  // Avoid short-circuiting here so we highlight all invalid fields
  // Validation of ACH fields is done by CollectJS
  valid() {
    var valid = true
    if (!this.authorizationValid()) { valid = false }
    if (!this.authorizationAccuracyValid()) { valid = false }
    if (!this.signatureValid()) { valid = false }
    return valid
  }

  fieldPresent(field) {
    if (field.value.length > 0) {
      field.classList.remove('is-invalid')
      return true
    } else {
      field.classList.add('is-invalid')
      return false
    }
  }

  authorizationValid() {
    if (!this.hasAuthorizationTarget) { return true }

    return this.fieldChecked(this.authorizationTarget)
  }

  authorizationAccuracyValid() {
    return this.fieldChecked(this.authorizationAccuracyTarget)
  }

  signatureValid() {
    if (!this.hasSignatureTarget) { return true }

    return this.fieldPresent(this.signatureTarget)
  }

  fieldChecked(field) {
    if (field.checked) {
      field.classList.remove('is-invalid')
      return true
    } else {
      field.classList.add('is-invalid')
      return false
    }
  }
}
