import { Controller } from 'stimulus'
import { replaceWith } from '../modules/replace_with'
import BSN from 'bootstrap.native'

export default class extends Controller {
  static targets = ['container', 'unexpectedError', 'submitButton']

  onSend(event) {
    this.unexpectedErrorTarget.classList.add('d-none')
    this.clearSubmitIcons()
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add('submit-icon')
      this.submitButtonTarget.classList.add('loading')
    }
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    let newContainer = replaceWith(this.containerTarget, data['html'])
    let button = newContainer.querySelectorAll('[data-target="forms.submitButton"]')[0]
    if (button) {
      button.classList.add('submit-icon')
      button.classList.add('loaded')
      button.classList.add('btn-success')
    }
  }

  onError(event) {
    let [data, status, xhr] = event.detail
    if (xhr.status == 422) {  // Data validation error
      replaceWith(this.containerTarget, data['html'])
    } else {                  // Unexpected error
      this.unexpectedErrorTarget.classList.remove('d-none')
    }
  }

  onComplete(event) {
    this.reactivateExpandableElements()
    this.clearSubmitIcons()
    document.dispatchEvent(new Event('formsOnComplete'))
  }

  onFormChange(event) {
    this.clearSubmitIcons()
  }

  clearSubmitIcons() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.remove('submit-icon')
      this.submitButtonTarget.classList.remove('loading')
      this.submitButtonTarget.classList.remove('loaded')
      this.submitButtonTarget.classList.remove('btn-success')
    }
  }

  reactivateExpandableElements() {
    document.querySelectorAll('*[data-bs-toggle="collapse"]').forEach((collapseEl) => {
      new BSN.Collapse(collapseEl)
    })
  }
}
