import { Controller } from 'stimulus'

// To avoid having to include all of jQuery just to get the Bootstrap JS plugins,
// we'll write our own handling.  This controller handles menu dropdowns.
export default class extends Controller {
  static targets = ['container', 'menu', 'dropdown']

  toggle(event) {
    this.menuTarget.classList.toggle('show')
    event.preventDefault()
  }

  onDocumentClick(event) {
    var target = event.target
    if (!this.containerTarget.contains(target)) {
      this.menuTarget.classList.remove('show')
    }
  }

  onUpdateSuccess(event) {
    let [data, status, xhr] = event.detail
    this.containerTarget.classList.remove('error')
    this.containerTarget.innerHTML = data.dropdown_html
    if (data.dropdown_callback) {
      eval(data.dropdown_callback)
    }
  }

  onUpdateError(event) {
    this.containerTarget.classList.add('error')
  }

  // We need to hide the dropdown before Turbolinks cached the page or else the dropdown
  // will flicker open when links are used.
  //
  //   Listen for the turbolinks:before-cache event if you need to prepare the document before
  //   Turbolinks caches it. You can use this event to reset forms, collapse expanded UI elements,
  //   or tear down any third-party widgets so the page is ready to be displayed again.
  //   https://github.com/turbolinks/turbolinks#preparing-the-page-to-be-cached
  //
  turbolinksBeforeCache() {
    this.menuTarget.classList.remove('show')
  }
}
